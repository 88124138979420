export default {
    bind(el, binding, vnode) {
      function formatNumber(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
  
      function updateValue(value) {
        const formattedValue = formatNumber(value);
        el.value = formattedValue;
        vnode.componentInstance.$emit('input', value);
      }
  
      el.addEventListener('input', (event) => {
        const value = event.target.value.replace(/,/g, '');
        if (!isNaN(value) && value !== '') {
          updateValue(value);
        } else {
          vnode.componentInstance.$emit('input', value);
        }
      });
  
      el.value = formatNumber(binding.value);
    },
    update(el, binding) {
      el.value = binding.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  